<template>
    <div class="container-home" id="container">
        <transition name="btn-scroll">
            <button class="btn-scroll" @click="scrollTop()" v-if="showScroll"></button>
        </transition>
        <page-header :isWhite="false"></page-header>
        <full-page ref="fullpage" :options="options" id="fullpage">
            <div class="section" data-anchor="firstPage">
                <div class="container-main first">
                    <div class="box-hama">
                        <div class="wrap-text-hama">
                            <div class="title-hama">
                                <span>H</span>armonious<br><span>A</span>dvancement for <span>MA</span>nKind.
                            </div>
                            <div class="text-hama">
                                하마랩은 AI기술 개발을 인류를 위한 화목한 발전으로 생각하며, <br> 국내 원천기술을 개발하겠다는 일념으로 설립되었습니다.
                                <br> 비전과 사람을 고민하는 AI기업 하마랩은 기획부터 개발, 고도화까지 <br> 다양한 영역에서 맞춤 인공지능 솔루션을 제공합니다.
                            </div>
                        </div>
                    </div>
                    <div class="img-hama"></div>
                </div>
            </div>
            <div class="section" data-anchor="secondPage">
                <div class="container-main sec">
                    <div class="box-smart" >
                        <div class="img-back-smart top"></div>
                        <div class="img-back-smart bottom"></div>
                        <div class="wrap-text-smart">
                            <div class="title-smart">
                                스마트 양식장 <br> 환경 관리 솔루션, 아쿠아누리
                            </div>
                            <div class="text-smart">
                                IOT기술로 양식장 환경을 <span>자동으로 모니터링</span>하고, <br> AI 기술로 <span>수질 변화를 예측, 어병 발생을 진단</span>합니다.
                            </div>
                        </div>

                        <div class="btn-main-aqua" @click="goAqua()">아쿠아누리 자세히 알아보기</div>

                        <div class="box-img-smart">
                            <div class="wrap-img-smart">
                                <div class="img-smart fir"></div>
                                <div class="text-smart-content">드론 촬영 & 자동 급이</div>
                            </div>
                            <div class="wrap-img-smart">
                                <div class="img-smart sec"></div>
                                <div class="text-smart-content">수질 측정 & 변화 예측</div>
                            </div>
                            <div class="wrap-img-smart">
                                <div class="img-smart thi"></div>
                                <div class="text-smart-content">양식장 전용 ERP</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <page-footer class="section footer" data-anchor="thirdPage"></page-footer>
        </full-page>
    </div>
</template>

<script>
// @ is an alias to /src
import "fullpage.js/dist/fullpage.css";
import PageFooter from "@/components/PageFooter";

let $this = {};
export default {
    name: "Home",
    mixins: [],
    components: {
        PageFooter,
    },
    props: {},
    data() {
        return {
            showScroll: false,
            options: {
                responsiveHeight: 900,
                responsiveWidth: 1280,
                autoScrolling: true,
                fitToSection: true,
                v2compatible: true,
                licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
                sectionsColor: ["#ffffff", "#ffffff", "#ffffff"],
                lockAnchors: true,
                verticalCentered: false,
                anchors: ["firstPage", "secondPage", "thirdPage"],
                menu: ".myMenu",
                navigation: false,

                onLeave: this.handleLeave
            },
        };
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        goAqua() {
            window.open('https://aquanuri.io/')

        },
        scrollTop() {
            fullpage_api.moveTo("firstPage");
        },

        handleLeave(origin, destination, direction) {
            if (destination == 1) {
                this.showScroll = false
            } else {
                this.showScroll = true
            }
        },
    },
    watch: {},
};
</script>
