<template>
    <div class="container-common" id="container">
        <transition name="btn-scroll">
            <button class="btn-scroll" @click="scrollTop()" v-if="showScroll"></button>
        </transition>
        <page-header :isWhite="isWhite"></page-header>
        <div class="container-top contact">
            <div class="text-top">
                CONTACT
            </div>
        </div>

        <div class="title-contact">
            오시는 길
        </div>
        <div class="box-contact-content">
            <div class="wrap-contact-content">
                <div class="title-contact-content">이메일 주소</div>
                <div class="text-contact-content">biz@hamagroups.io</div>
            </div>
            <div class="wrap-contact-content">
                <div class="title-contact-content">전화번호</div>
                <div class="text-contact-content">+82) 70-5151-5884</div>
            </div>
            <div class="wrap-contact-content">
                <div class="title-contact-content">팩스</div>
                <div class="text-contact-content">+82) 70-7611-6107</div>
            </div>
        </div>

        <div class="container-map">
            <div class="box-map">
                <div class="wrap-map">
                    <div class="img-map fir"></div>
                    <div class="text-map">
                        본사 : 경기도 성남시 수정구 달래내로 46, 성남글로벌융합센터 306호, 606호
                    </div>
                </div>
                <div class="wrap-map">
                    <div class="img-map sec"></div>
                    <div class="text-map">
                        연구소 : 제주특별자치도 제주시 원남 6길 58 201호
                    </div>
                </div>
            </div>
        </div>
        <page-footer></page-footer>
    </div>
</template>
<script>

import PageFooter from "@/components/PageFooter";

export default {
    name: "Contact",
    mixins: [],
    components: {PageFooter},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            isWhite: true,
            showScroll:false,
        }
    },
    beforeRouterEnter() {

    },
    created() {
    },
    mounted() {
        document.getElementById('container').addEventListener('scroll', () => {
            this.setScroll(event)
        })

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setScroll(event) {
            if(event.target.scrollTop > 330){
                this.isWhite = false
                this.showScroll = true
            }else{
                this.isWhite = true
                this.showScroll = false
            }

        },

        scrollTop() {
            let container = document.getElementById('container')

            container.scrollTo({top: 0,behavior:'smooth'});
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

