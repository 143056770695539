<template>
    <div class="container-header" :class="{ 'white' : isWhite}">
        <div class="wrap-header">
            <div class="logo-header" @click="$router.push('/')"></div>
            <div class="wrap-btn-header">
                <div class="btn-header" @click="$router.push(`/about`)">ABOUT US</div>
                <div class="btn-header" @click="goAqua()">AQUA NURI</div>
                <div class="btn-header" @click="$router.push('/contact')">CONTACT</div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: 'PageHeader',
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        activeHeader: {
            type: Boolean,
            default: false,
        },
        isWhite: {
            type: Boolean,
            default: true,
        },
        headerAbsolute: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        goAqua() {
            window.open('https://aquanuri.io/')

        },
    },
    watch: {},
}
</script>

<style scoped>


</style>
