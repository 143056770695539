import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from "lodash";
import API from '@/api'
import moment from 'moment';
import VueMomentJs from 'vue-momentjs';
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
import 'element-ui/lib/theme-chalk/index.css'
import VModal from 'vue-js-modal';
import 'expose-loader?$!expose-loader?jQuery!jquery'
import Storage from 'vue-web-storage';
import VueFullPage from 'vue-fullpage.js'
import 'fullpage.js/vendors/scrolloverflow'
import PageHeader from '@/components/PageHeader';
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.component("page-header", PageHeader);
window.$ = require('jquery')
Vue.use(VueMomentJs, moment);
Vue.use(VModal);
Vue.use(API);
Vue.use(VueFullPage);
Vue.use(ElementUI, {locale});
Object.defineProperty(Vue.prototype, '$_' +
  '' +
  '', { value: _ });
Vue.config.productionTip = false;
Vue.use(Storage, {
  prefix: 'com.dgmong.preset', // default `app_`
  drivers: ['local'], // default 'local'
});

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAfNBAR9uoxd7W9fMgbx7aXGr8BobmRr8w',
        libraries: 'places',
        region: "KR",
    },
    installComponents: true
})


new Vue({
  router : router,
  store : store,
  render: h => h(App)
}).$mount('#app')
